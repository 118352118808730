import { DivContainer, DivContainer50 } from "common/components/divContainer";
import { Iso } from "./iso";
import { Node } from './node';

interface Props{
    isLoadingEnabled: boolean
}
export const IsoNode = ({ isLoadingEnabled }: Props) => {

    /**Constants */
    const labelTitleIso = "ISO";
    const labelTitleNode = "LMP";

    return (

        <DivContainer>
            <>
                <DivContainer50 label={labelTitleIso}>
                    <Iso isLoadingEnabled={isLoadingEnabled} />
                </DivContainer50>

                <DivContainer50 label={labelTitleNode}>
                    <Node isLoadingEnabled={isLoadingEnabled} />
                </DivContainer50>
            </>
        </DivContainer>
    )
}
